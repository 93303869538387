// Module Imports
import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// Custom Imports
import Layout from "../components/layout"
import StaticQueryHTML from "../components/static-query-html"

const ImprintPage = () => {
  const data = useStaticQuery(graphql`
    query StaticQueryImprint {
      markdownRemark(frontmatter: { title: { eq: "Imprint Content" } }) {
        html
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>la rabasse Invest GmbH - Impressum</title>
      </Helmet>
      <StaticQueryHTML data={data} />
    </Layout>
  )
}

export default ImprintPage
