// Module Imports
import React from "react"

// Custom Imports
import { Container } from "../elements"

const StaticQueryHTML = ({ data }) => (
  <main>
    <Container dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
  </main>
)

export default StaticQueryHTML
